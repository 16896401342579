.fullPage {
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
.app {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
