* {
  font-family: 'Rubik', sans-serif;
  margin: 0;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
*::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

html {
  scroll-behavior: smooth;
}
