.currentWeather {
  padding: 1rem 0 0.5rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  background-color: rgb(255, 255, 255, 0.5);
}

.dateAndTime {
  padding: 2rem 3rem 2rem 3rem;

  text-align: center;

  background-color: rgb(224, 242, 254);
}

.dateAndTime .location {
  font-size: 2rem;
  font-weight: 700;
}

.temperature {
  margin: 2rem 0 2rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.temperature .digits {
  font-size: 5rem;
}

.temperature .units {
  font-size: 2rem;
}

.temperature .right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.right .tendency {
  padding: 0.25rem;
}

.units .separator {
  margin: 0.5rem;
  font-weight: 700;
}

.otherStats {
  width: 100%;
  padding: 1rem 0;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stat img {
  margin: 0 0 1rem 0;
}

.down-arrow {
  margin-top: 0.5rem;
}

.error {
  padding: 1rem;
}
