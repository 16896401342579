.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;

  transition: all 0.25s;
}
